import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './landing.css';

function Landing({ onLogin }) {
const { loginWithRedirect } = useAuth0();


  return (
    <div className="landing-container">
      <div className="landing-content">
        <div className="logo">
        <img
            src="https://www.neartekpod.com/hubfs/raw_assets/public/ntp-2.0-main/assets/logo.png"
            className="w-32 p-2  md:w-[196px]"
            alt="Neartekpod Logo"
          />
          {/* <span className="logo-bars">|||</span>
          <span className="logo-text">neartekpod</span> */}
        </div>
        <h1 className="welcome-text">Welcome to</h1>
        <h2 className="ntp-text">NTP.io</h2>
        <button className="login-button" onClick={() => loginWithRedirect()}>Login</button>
      </div>
    </div>
  );
}

export default Landing;