const config = {
  // API_BASE_URL: "https://38mbesti07.execute-api.us-east-1.amazonaws.com/NTPServerAutomation",
  API_BASE_URL: "https://v2c0n1zyig.execute-api.us-east-1.amazonaws.com",
  SERVICES_STRING:
    "dev1.lcp,dev2.lcp,dev.genesis,dev.jacana,qa.jacana,sandbox.jacana,warrantyai.lcp,voiceshop.lcp",
  Token:
    "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkctQk1NR2UtaW5hVXRDdTVNV09XaCJ9.eyJpc3MiOiJodHRwczovL2Rldi1ic3o4ZzJpbmE1bHJ3ZnczLnVzLmF1dGgwLmNvbS8iLCJzdWIiOiIzWkJ4aHdrbXdMTGdIRWpHaFMwbFdZS3lwUlN6OEJJS0BjbGllbnRzIiwiYXVkIjoiaHR0cHM6Ly9kZXYtYnN6OGcyaW5hNWxyd2Z3My51cy5hdXRoMC5jb20vYXBpL3YyLyIsImlhdCI6MTcyOTc1NTE0MiwiZXhwIjoxNzI5ODQxNTQyLCJndHkiOiJjbGllbnQtY3JlZGVudGlhbHMiLCJhenAiOiIzWkJ4aHdrbXdMTGdIRWpHaFMwbFdZS3lwUlN6OEJJSyJ9.VMf35mVsqyik8gqUar-Px-_72-DUJbfBCsYh7cdlFWQ0t0e3YwKb7l9f4CEO9UF8XJ5eFJJXYADPs761oKDjiTgbKesyXdJd2vkyeurxK-aXYB4OLxW60ywcDpg-DiK2TRCNucmLIXnFaMAOEwswqYSGdiBmxxcZPI8Lvw7a6rC63F8m0XWZcw9rvzpp3XB4N0VmCf4GaITBQ0gV8tJacp4DgvF6fRr-opFnBuUTVR5vkb5LiauyHZMupSPvE8GiLPM3lSe7JP-vN0f_RbT5GxQ78PljpAEw-FKaLpPFB5EMAUKY1waND1i4SVCR6-zF-GfEfEOVNElxoEhSqNNDng",
};

export default config;
