import React, { useState } from 'react';
import Landing from './Landing';
import Home from './Home';
import { useAuth0 } from '@auth0/auth0-react';
import './Home.css';

function App() {
  const { isAuthenticated } = useAuth0();

  const handleLogin = () => {
    // This function would typically handle the actual login process
    // For now, we'll just set isLoggedIn to true
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    // This function handles the logout process
    setIsLoggedIn(false);
  };

  return (
    <div className="app">
      {isAuthenticated ? (
        <Home  />
      ) : (
        <Landing  />
      )}
    </div>
  );
}

export default App;
