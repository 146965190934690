import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import Hiring from "../Asserts/Images/Hiring.svg";
import Hiring_icon from "../Asserts/Images/Hiring_icon.svg";
import Expense from "../Asserts/Images/Expense.svg";
import Expense_icon from "../Asserts/Images/Expense_icon.svg";
import Projects from "../Asserts/Images/Projects.svg";
import Projects_icon from "../Asserts/Images/Projects_icon.svg";
import start_icon from "../Asserts/Images/Start_icon.svg";
import stop_icon from "../Asserts/Images/stop_icon.svg";

//import { useNavigate } from 'react-router-dom';

/// //////// Admin codes

const Admin = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projectStatuses, setProjectStatuses] = useState({});

  const {
    user,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  // const apiUrls = {
  //   "LCP - Dev 1": {
  //     start: "https://bz0pbxd5fa.execute-api.us-east-1.amazonaws.com/start",
  //     stop: "https://tx9tmw7n74.execute-api.us-east-1.amazonaws.com/stop",
  //     status: "https://rdgagyppk9.execute-api.us-east-1.amazonaws.com/status",
  //   },
  //   "LCP - Dev 2": {
  //     start: "https://brgs0ucrwd.execute-api.us-east-1.amazonaws.com/mulstart",
  //     stop: "https://o69eyfkw58.execute-api.us-east-1.amazonaws.com/mulstop",
  //     status:
  //       "https://ple65a214k.execute-api.us-east-1.amazonaws.com/mulstatus",
  //   },
  // };
  useEffect(() => {
    const checkAuthentication = async () => {
      if (isLoading) {
        console.log("Auth0 is still loading...");
        return;
      }

      if (!isAuthenticated) {
        console.log("User is not authenticated, redirecting to login...");
        await loginWithRedirect();
        return;
      }

      try {
        console.log("Attempting to get access token...");
        const token = await getAccessTokenSilently();
        console.log("Access token obtained");
        setLoading(false);
      } catch (error) {
        console.error("Error getting access token:", error);
        if (error.error === "login_required") {
          console.log("Silent auth failed, redirecting to login...");
          await loginWithRedirect();
        } else {
          setError("An error occurred during authentication");
          setLoading(false);
        }
      }
    };

    checkAuthentication();
  }, [isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect]);

  // useEffect(() => {
  //   const fetchProjectStatuses = async () => {
  //     if (!isAuthenticated) return;

  //     const statuses = {};
  //     for (const [project, urls] of Object.entries(apiUrls)) {
  //       try {
  //         const response = await axios.get(urls.status);
  //         statuses[project] = response.data; // Store the entire response object
  //       } catch (error) {
  //         console.error(`Error fetching status for ${project}:`, error);
  //         statuses[project] = { status: "unknown" }; // Use an object with a status property for consistency
  //       }
  //     }
  //     setProjectStatuses(statuses);
  //   };

  //   fetchProjectStatuses();
  // }, [isAuthenticated]);

  const handleButtonClick = async (project) => {
    const isRunning = projectStatuses[project]?.status === "UP";
    const url = isRunning ? apiUrls[project].stop : apiUrls[project].start;

    try {
      await axios.post(url);
      // Refetch the status after starting/stopping
      const response = await axios.get(apiUrls[project].status);
      setProjectStatuses((prev) => ({
        ...prev,
        [project]: response.data,
      }));
    } catch (error) {
      console.error(
        `Error ${isRunning ? "stopping" : "starting"} ${project}:`,
        error
      );
    }
  };

  if (loading || isLoading) {
    return <div>Loading... Please wait.</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {/* Header */}
      <nav>
        {/* <div className=" flex justify-between w-full mx-auto font-medium font-montserrat items-center text-sm">
        <img
          src="https://www.neartekpod.com/hubfs/raw_assets/public/ntp-2.0-main/assets/logo.png"
          className="w-32 p-2  md:w-[196px]"
          alt="Neartekpod Logo"
        />
         <div>
         <span className="items-end"> Welcome {user.name} </span>
         <button
          className="logout-btn"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Logout
        </button>
         </div>
        </div> */}
      </nav>
      <header className="text-center">
        <h1 className="text-2xl md:text-4xl font-montserrat font-bold text-gray-800 mt-5 md:mt-0">
          Welcome to <span className="text-Near">Near</span>
          <span className="text-tek">tek</span>
          <span className="text-customBlue">pod</span>
        </h1>
        <p className="text-gray-700 text-center text-sm font-montserrat">
          Your Partner in Transforming Technology into Success
        </p>

       

        
      </header>
      <div className="bg-Near absolute bottom-0 w-32 h-60 -z-50 rounded-full blur-[180px] left-0 opacity-80"></div>
      <div className="bg-Near absolute bottom-6 w-32 h-60 -z-50 rounded-full blur-[180px] right-0 opacity-80"></div>

      <div className="flex flex-col md:flex-row justify-center my-6 md:my-16">
        {/* Features Section */}
        <div className="flex flex-nowrap md:flex-wrap justify-center mx-12 md:m-12 gap-4 md:gap-10 basic-1/2">
          {/* Feature Card 1 */}
          <div className="p-0 md:p-3 flex flex-col items-center justify-left transition-transform transform hover:scale-105">
            {/* Image centered horizontally */}
            <div className="flex justify-left pl-0 md:pl-7 w-24 md:w-60 h-40 md:h-96 pb-8">
              <img src={Hiring} alt="Hiring" className="mb-8 h-full w-full" />
            </div>

            {/* Icon with button */}
            <div className="flex items-center gap-2 bg-buttoncolor text-textcolor py-2 px-4 rounded-full transition text-xs md:text-sm">
              <img
                src={Hiring_icon}
                alt="Hiring_icon"
                className="md:w-4 md:h-4 hidden md:flex"
              />
              <button
                className="text-white font-montserrat"
                onClick={() => {
                  window.open("https://lcp.neartekpod.io/", "_blank");
                }}
              >
                Hiring
              </button>
            </div>
          </div>

          <div className="p-0 md:p-3 flex flex-col items-center justify-left transition-transform transform hover:scale-105">
            {/* Image centered horizontally */}
            <div className="flex justify-left pl-0 md:pl-7 w-24 md:w-60 h-40 md:h-96 pb-8">
              <img src={Expense} alt="Hiring" className="mb-8 h-full w-full" />
            </div>

            {/* Icon with button */}
            <div className="flex items-center gap-2 bg-buttoncolor text-textcolor py-2 px-4 rounded-full transition text-xs md:text-sm">
              <img
                src={Expense_icon}
                alt="Expense_icon"
                className="md:w-4 md:h-4 hidden md:flex"
              />
              <button
                className="text-white font-montserrat"
                // onClick={() => {
                //   window.open(
                //     "https://lcp.neartekpod.io/",
                //     "_blank"
                //   );
                // }}
              >
                Expense
              </button>
            </div>
          </div>
          <div className="p-0 md:p-3 flex flex-col items-center justify-left transition-transform transform hover:scale-105">
            {/* Image centered horizontally */}
            <div className="flex justify-left pl-0 md:pl-7 w-24 md:w-60 h-40 md:h-96 pb-8">
              <img
                src={Projects}
                alt="Projects"
                className="mb-8 h-full w-full"
              />
            </div>

            {/* Icon with button */}
            <div className="flex items-center gap-2 bg-buttoncolor text-textcolor py-2 px-4 rounded-full transition  text-xs md:text-sm">
              <img
                src={Projects_icon}
                alt="Projects_icon"
                className="md:w-4 md:h-4 hidden md:flex"
              />
              <button className="text-white font-montserrat">Projects</button>
            </div>
          </div>
        </div>

        {/* Projects Section */}
        {/* <div className="flex flex-wrap justify-center gap-10 mx-10 md:m-10 basic-1/2">
          <section className="md:text-y">
            <h2 className="text-xl md:text-3xl my-10 font-medium ml-0 mr-20 font-montserrat">
              Our Projects
            </h2>
            <div className="space-y-4 font-montserrat">
              {Object.entries(apiUrls).map(([project, urls]) => (
                console.log('project',project),
                console.log('projectStatuses[project]?.status',projectStatuses[project]?.status),
                <div
                  key={project}
                  className="flex justify-between items-center bg-gray-100 rounded-lg"
                >
                  <span className="border-solid border-2 py-2 px-4 md:0 text-xs md:text-sm rounded-full">
                    {project}
                  </span>
                  <div className="space-y-4 ml-10">
                    <button
                      onClick={() => handleButtonClick(project)}
                      className={`flex items-center gap-2 ${
                        projectStatuses[project]?.status === "UP"
                          ? "bg-buttoncolor text-textcolor"
                          : "bg-Near text-textcolor"
                      } font-montserrat py-2 px-4 md:0 text-xs md:text-sm rounded-full transition`}
                    >
                      <img
                        src={
                          projectStatuses[project]?.status === "UP"
                            ? stop_icon
                            : start_icon
                        }
                        alt={
                          projectStatuses[project]?.status === "UP"
                            ? "stop_icon"
                            : "start_icon"
                        }
                        className="w-3 h-3 font-montserrat font-medium"
                      />
                      {projectStatuses[project]?.status === "UP"
                        ? "Stop"
                        : "Start"}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div> */}
      </div>

      {/* Footer */}
      {/* <footer className="text-xs md:text-sm text-gray-500 flex  z-50 bg-textcolor h-12 justify-center w-full p-2">
        <div className="container flex justify-between w-full mx-auto font-medium font-montserrat items-center text-xs md:text-sm">
          <p className="mr-10">©2024 – Neartekpod | All rights reserved</p>

          <a href="#" className="hover:underline mr-8">
            Terms and conditions | Privacy policy
          </a>
        </div>
      </footer> */}
    </div>
  );
};
export default Admin;
