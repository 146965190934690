import React from "react";
import Header from "./LoggedInHeader";
import KeyApps from "./KeyApps";
import Admin from "./Dashboard/Admin";
import ServiceStatus from "./ServiceStatus";
import DemoTable from "./DemoProjects/DemoTable";

function Home() {
  return (
    <div className=" home-container text-center md:text-center">
      <Header />
      <div className="content flex flex-col md:flex-row">
        <Admin />
        {/* <KeyApps /> */}
        <div></div>
        <ServiceStatus />
      </div>
      <div  className=" p-4 md:p-10">
      <DemoTable />
      </div>

      
      <footer className="text-xs md:text-sm text-gray-500 flex  z-50 bg-textcolor h-12 justify-center w-full p-2">
        <div className="container flex justify-between w-full mx-auto font-medium font-montserrat items-center text-xs md:text-sm">
          <p className="mr-10">©2024 – Neartekpod | All rights reserved</p>

          <a href="#" className="hover:underline mr-8">
            Terms and conditions | Privacy policy
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Home;
