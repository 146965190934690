import React from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import config from "./config";
import "./index.css";
window.appConfig = config;
const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Auth0Provider
      // domain="dev-q6gutscasj0vu7ze.us.auth0.com"
      // clientId="j9B8CHj5jr6qYkwmSXh6HBbJftgju8ma"
      // domain="dev-bsz8g2ina5lrwfw3.us.auth0.com"
      // clientId="3ZBxhwkmwLLgHEjGhS0lWYKypRSz8BIK"
      domain="dev-bsz8g2ina5lrwfw3.us.auth0.com"
      clientId="4Udq6qUC0lmHXJz3tDinX04nErAkFjaq"
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
